
h1 {
    font-weight: 600;
    font-size: 3.0rem;
    /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
@media only screen and (max-width: 1200px) {
    h1 {
        font-weight: 550;
        font-size: 40px;
    }
}
@media only screen and (max-width: 767px) {
    h1 {
        font-size: 25px;
        font-weight: 600;
    }
}

h3 {
    font-weight: 150;
    line-height: 1.25em;
    font-family:Georgia, 'Times New Roman', Times, serif;

}
@media only screen and (max-width: 767px) {
    h3 {
        font-size: 17px;
        font-weight: 200;
        line-height: 1.0em;
    }
}

h2 {
    font-weight: 150;
    line-height: 1.25em;
    font-family:Georgia, 'Times New Roman', Times, serif;

}
@media only screen and (max-width: 767px) {
    h2 {
        font-size: 22px;
        font-weight: 200;
        line-height: 1.0em;
    }
}
a:link,a:visited {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}
a:hover {
    text-decoration: none;
}

@media only screen and (min-width: 992px) {
    #navbarScroll {
        margin-left: 40%;
    }
    .searchbox {
        margin-left : 10px;
    }
}
